import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import FreeCreditEvaluationWrapper from "@components/layouts/freeCreditEvaluationWrapper";
import ServiceForm from "@components/freecreditevaluation/serviceform";

const WhyUs = dynamic(() => import("@components/freecreditevaluation/whyUs"));

const FAQ = dynamic(() => import("@components/freecreditevaluation/faq"));
const HowThisWork = dynamic(
    () => import("@components/freecreditevaluation/howThisWork"),
);
const OffersList = dynamic(() => import("@components/shared/offersList"));

import { useDomainContext } from "@hooks/useDomainContext";

export default function FreeCreditEvaluationHome(): ReactElement {
    const { domain } = useDomainContext();
    const style = {
        background: "#fff",
        borderColor: "#e3e3e3",
        buttonBackground: "#3ea636",
        buttonForground: "#fff",
    };

    return (
        <FreeCreditEvaluationWrapper>
            <ServiceForm />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} style={style} />
            </section>
            <HowThisWork />
            <WhyUs />
            <FAQ />
        </FreeCreditEvaluationWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
